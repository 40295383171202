import React, { useState, useEffect } from 'react';
import { Frame, Page, ResourceList, Text, Thumbnail, Layout, Modal, Button, Card, Select, Toast, Banner, FormLayout, TextField } from '@shopify/polaris';

export default function ProductList() {
  
  const [storeDomain] = useState(new URL(window.location).searchParams.get("shop"));
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fields, setFields] = useState([{ shopifyProductId: '', discordRole: '' }]);
  const [selectedProductIdIndex, setSelectedProductIdIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [discordRoles, setDiscordRoles] = useState([]);
  const [activeProStatus, setActiveProStatus] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {

    async function fetchSettings() {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.integrations && data.integrations.discord_products && data.integrations.discord_products[0]) {
            setFields(data.integrations.discord_products.map(({ shopifyProductId, discordRole }) => ({
              shopifyProductId,
              discordRole: discordRole
            })));
          } else {
            setFields([{ shopifyProductId: '', discordRole: '' }]);
          }
          if (data.subscriptions) {
            data.subscriptions.forEach(function(recurr) {
              if(recurr.name === "Premium Plan" && recurr.status === "active"){
                  setActiveProStatus(true);
              }
              });
          }
        } else {
          console.error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
    fetchSettings();

    async function fetchProducts() {
      try {
        const response = await fetch(`/api/products_details?shop=${storeDomain}`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProducts(data);
        } else {
          console.error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
    fetchProducts();
    
    async function fetchDiscordRoles() {
      try {
        const response = await fetch(`/api/fetch_all_roles?shop=${storeDomain}`,{
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });
        if (response.ok) {
          const data = await response.json();
          setDiscordRoles(data);
        } else {
          console.error('Failed to fetch discord roles');
        }
      } catch (error) {
        console.error('Error fetching discord roles:', error);
      }
    }
    fetchDiscordRoles();
    
  }, [storeDomain, selectedProducts.length]);

  useEffect(() => {
    if (products.length > 0 && selectedProducts.length === 0) {
      setSelectedProducts([products[0].id]);
    }
  }, [products, selectedProducts]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleInputChange = (index, event) => {
    if (event && event.target) {
      const values = [...fields];
      values[index][event.target.name] = event.target.value;
      setFields(values);
    }
  };  

  const handleAddField = () => {
    setFields([...fields, { shopifyProductId: '', discordRole: '' }]);
  };

  const handleRemoveField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  const handleOpenProductListModal = (index) => {
    setSelectedProductIdIndex(index);
    setModalOpen(true);
  };

  const handleProductSelection = (productId) => {
    const values = [...fields];
    values[selectedProductIdIndex].shopifyProductId = productId;
    setFields(values);
    setModalOpen(false);
  };

  const handleSubmit = async () => {

    const hasBlankProductIds = fields.some(field => field.shopifyProductId === '');
    if (hasBlankProductIds) {
      console.error('Please select a product for all fields');
      return;
    }

    const discord_products = fields;
    console.log('Fields:', discord_products);

    setActive((active) => !active);
    const response = await fetch(`/api/update_config?shop=${storeDomain}`, {
        method: "POST", 
        headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
        },
        body: JSON.stringify({discord_products}),
    });

    if (response.ok) {
        console.log(response.status);
    } else {
        console.error('Failed to save settings');
    }
    return;
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDismiss = async () => {
    setActive((active) => !active);
  };

  const toastMarkup = active ? (
    <Toast content="Saved Products Settings Successfully" onDismiss={handleDismiss} />
  ) : null;

  return (
    <div style={{ height: '250px' }}>
      <Frame>
        <Page
          title="Product Integration"
          primaryAction={{ content: 'Save', onAction: handleSubmit }}
        >
          <FormLayout>
            {((activeProStatus && activeProStatus === true)) ? (
                <></>
            ) : (
              <Banner tone="info"><b>Upgrade Premium Plan</b> to use Product Integration Features</Banner>
            )}
            
          <Layout>
          <Layout.Section>
            <Modal
              open={modalOpen}
              onClose={closeModal}
              title="All Products"
              primaryAction={{
                content: 'Select Products',
                onAction: () => {},
              }}
              secondaryActions={[
                {
                  content: 'Close',
                  onAction: closeModal,
                },
              ]}
            >
              <Modal.Section>
                <TextField
                  label="Search products"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  autoComplete="off"
                  placeholder="Search by product title"
                />
              <Modal.Section></Modal.Section>
                <ResourceList
                  resourceName={{ singular: 'product', plural: 'products' }}
                  items={filteredProducts}
                  renderItem={(item) => {
                    const { id, title, images, price } = item;
                    const media = images && images.length > 0 ? (
                      <Thumbnail
                        source={images[0].src}
                        alt={`Thumbnail for ${title}`}
                        size="medium"
                      />
                    ) : (
                      <Thumbnail
                        source="https://via.placeholder.com/150"
                        alt="Placeholder Image"
                        size="medium"
                      />
                    );
                    const productPrice = price || 'N/A';
                    return (
                      <ResourceList.Item id={id} onClick={() => handleProductSelection(id)}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {media}
                          <div style={{ marginLeft: '16px' }}>
                            <h3>
                              <Text variation="strong">{title}</Text>
                            </h3>
                            <p>Price: {productPrice}</p>
                          </div>
                        </div>
                      </ResourceList.Item>
                    );
                  }}
                />
              </Modal.Section>
            </Modal>
            <Card title="Product Details" sectioned>
              <FormLayout>
              {((discordRoles.length > 0)) ? (
                <></>
              ) : (
                <Banner tone="warning">Missing <b>Guild ID</b> and <b>Bot Token</b> from "Member & Role Integration" tab </Banner>
              )}
              {fields.map((field, index) => (
                <div key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'flex-end' }}>
                  <div style={{ display: 'inline', marginRight: '20px' }}>
                    <Text variant="headingSm" as="h6">Shopify Product ID {index + 1}</Text>
                    <Select
                      name="shopifyProductId"
                      options={products.map(product => ({
                        label: `${product.title} (${product.id})`,
                        value: product.id,
                      }))}
                      value={field.shopifyProductId}
                      onChange={(value) => handleInputChange(index, { target: { name: 'shopifyProductId', value } })}
                    />
                  </div>
                  <div style={{ display: 'inline', marginRight: '20px' }}>
                    <Button onClick={() => handleOpenProductListModal(index)} variant="primary">Select Product</Button>
                  </div>
                  <div style={{ display: 'inline', marginRight: '20px' }}>
                    <Text variant="headingSm" as="h6">Discord Role ID {index + 1}</Text>
                    <Select
                      name="discordRole"
                      options={discordRoles.map(role => ({
                        label: `${role.name} (${role.id})`,
                        value: role.id,
                      }))}
                      value={field.discordRole}
                      onChange={(value) => handleInputChange(index, { target: { name: 'discordRole', value } })}
                    />
                  </div>
                  <div>
                    <Button onClick={() => handleRemoveField(index)} variant="primary" tone="critical"> Delete</Button>
                  </div>
                </div>
              ))}
              <div style={{ display: 'inline' }}>
                <Button onClick={handleAddField} variant="primary" tone="success" disabled={!activeProStatus} >+ Add Field</Button>
              </div>
              </FormLayout>
            </Card>
          </Layout.Section>
          </Layout>
          {toastMarkup}
          <Page></Page>
          <Page></Page>
          <Page></Page>
          </FormLayout>
        </Page>
      </Frame>
    </div>
  );
}
