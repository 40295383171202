import React, { useState, useEffect, useCallback } from "react";
import { Page, Layout, FormLayout, Card, TextField, Form, Toast, Frame, Checkbox, Button, InlineStack, Collapsible, Link, Spinner, Banner, ProgressBar } from "@shopify/polaris";
import { ExternalMinor, ConnectMinor, PlayCircleMajor, FileFilledMinor, QuestionMarkMajor } from '@shopify/polaris-icons';
import RatingBanner from "./rating";
import SupportForm from "./support";

export default function Configuration() {
  
  const originUrl = window.location.host;
  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [enableDiscord, setEnableDiscord] = useState(false);
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [scope, setScope] = useState('identify+email+guilds.join');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [checkMultipassToken, setCheckMultipassToken] = useState(false);
  const [multipassToken, setMultipassToken] = useState('');
  const [callbackId, setCallbackId] = useState('');
  const [activeProStatus, setActiveProStatus] = useState(false);
  const [activeBasicStatus, setActiveBasicStatus] = useState(false);
  const [registrationCount, setRegistrationCount] = useState(0);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkThemeApp, setCheckThemeApp] = useState(false);
  const [openInstruction, setOpenInstruction] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const callbackUrl = `https://${originUrl}/auth/discord/callback/${callbackId}`;
  const discordDeveloperURL = <Link url="https://discord.com/developers/applications/" target="_blank">developer portal link</Link>;
  const [active, setActive] = useState(false);

  const toggleSupportModal = () => {
    setSupportModalOpen((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.settings.discord_settings && data.id) {
            const storeData = data.settings.discord_settings;
            setCallbackId(data.id);
            setEnableDiscord(storeData.enableDiscord);
            setClientId(storeData.clientId);
            setClientSecret(storeData.clientSecret);
            setScope(storeData.scope);
            setRedirectUrl(storeData.redirectUrl);
            setCheckMultipassToken(storeData.checkMultipassToken);
            setMultipassToken(storeData.multipassToken);
            setRegistrationCount(data.custom.registrationCount || 0);
          }
          if (data.subscriptions) {
            data.subscriptions.forEach(function(recurr) {
                if(recurr.name === "Basic Plan" && recurr.status === "active"){
                    setActiveProStatus(true);
                } else if(recurr.name === "Premium Plan" && recurr.status === "active"){
                    setActiveBasicStatus(true);
                }
            });
          }
          console.log(response.status);
        } else {
          console.log(response.status);
        }

        const response1 = await fetch(`/api/check_theme_app?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });
        if (response1.ok) {
          const data = await response1.json();
          setCheckThemeApp(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [storeDomain]);

  const handleToggle = useCallback(() => setOpenInstruction((openInstruction) => !openInstruction), []);

  const handleCopy = () => {
    navigator.clipboard.writeText(callbackUrl);
    setCopied(true);
  };  

  const handleClick = () => {
    window.open(`/auth/discord?shop=${storeDomain}&state=check_connect`, '_blank', 'width=800, height=400');
  };

  const handleSubmit = async () => {

    setLoading(true);
    const discord_settings = {
      enableDiscord,
      clientId,
      clientSecret,
      scope,
      redirectUrl,
      checkMultipassToken,
      multipassToken,
    };

    setActive((active) => !active);
    const response = await fetch(`/api/update_config?shop=${storeDomain}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'x-shopify-shop-domain': storeDomain
      },
      body: JSON.stringify({discord_settings}),
    });

    if (response.ok) {
      console.log(response.status);
    } else {
      console.error('Failed to save settings');
    }
    setLoading(false);
    return;
  };

  const handleDismiss = async () => {
    setActive((active) => !active);
  };

  const handleVisitStoreEmbed = async () => {
    const url = `https://${storeDomain}/admin/themes/current/editor?&context=apps&template=customers/login&activateAppId=b5fd0c86-4525-4284-bed9-4483e61bdbed/discord-app-block&target=mainSection`;
    window.open(url, '_blank');
  };

  const handleYoutubeVideo = async () => {
    const url = `https://youtu.be/W1iw4Et-f8o`;
    window.open(url, '_blank');
  };

  const toastMarkup = active ? (
    <Toast content="Saved Settings Successfully" onDismiss={handleDismiss} />
  ) : null;

  return (
    <div style={{ height: '250px' }}>
      <Frame>
        <Page
          title="Discord Settings"
          primaryAction={{ content: loading ? <Spinner size="small" color="teal" /> : 'Save', onAction: handleSubmit }}
          secondaryActions={[
            {
              content: 'Help',
              icon: QuestionMarkMajor,
              onAction: toggleSupportModal,
            },
            {
              content: 'Enable App on Store',
              icon: ExternalMinor,
              accessibilityLabel: 'Enable App',
              onAction: handleVisitStoreEmbed,
            },
          ]}
        >
          <FormLayout>
            {((activeProStatus && activeProStatus === true) || (activeBasicStatus && activeBasicStatus === true)) ? (
                <></>
              ) : (
                <Banner>
                  <b>Free plan</b> remaining counts to log-in and register is one each month.
                  <div style={{ width: 325, display: 'flex', alignItems: 'center' }}>
                    <ProgressBar progress={parseInt(registrationCount)} tone="primary" size="small" />
                    <span style={{ marginLeft: '8px' }}>{registrationCount}/100</span>
                  </div>
                </Banner>
              )}
            {((checkThemeApp && checkThemeApp === true)) ? (
                <></>
              ) : (
                <Banner
                  title="You have not 'Enable Discord Connect App' on your store please click button below to enable it"
                  tone="warning"
                  action={{ content: 'Enable App on Store', icon: ExternalMinor, onAction: handleVisitStoreEmbed }}
                  onDismiss={() => {}}
                />
              )}
            <RatingBanner /><br />
          </FormLayout>
          <Layout>
            <Layout.AnnotatedSection
              title="Discord Configuration"
              description="Enter your discord configuration"
            >
              <Card sectioned>
                <Form>
                  <FormLayout>
                    <Checkbox
                      label="Enable Discord Login"
                      checked={enableDiscord}
                      onChange={setEnableDiscord}
                    />
                    <TextField
                      label="Enter Discord Client ID"
                      value={clientId}
                      onChange={setClientId}
                      placeholder="Client ID"
                    />
                    <TextField
                      label="Enter Discord Client Secret"
                      value={clientSecret}
                      onChange={setClientSecret}
                      placeholder="Client Secret"
                    />
                    <TextField
                      label="Enter Discord Scope"
                      value={scope}
                      onChange={setScope}
                      placeholder="Scope"
                      readOnly
                    />
                    <TextField
                      label="Callback URL"
                      value={callbackUrl}
                      readOnly
                      connectedRight={
                        <Button onClick={handleCopy} variant="primary" accessibilityLabel="Copy Callback URL">
                          {copied ? 'Copied!' : 'Copy'}
                        </Button>
                      }
                    />
                    <InlineStack>
                      <div style={{ display: 'flex' }}>
                        <Button onClick={handleClick} variant="primary" tone="success" icon={ConnectMinor} accessibilityLabel="Enable App">Test Connection</Button>
                        <span style={{ marginRight: '10px' }}></span>
                        <Button onClick={handleYoutubeVideo} variant="primary" tone="critical" icon={PlayCircleMajor} accessibilityLabel="Watch Video">Watch Video</Button>
                        <span style={{ marginRight: '10px' }}></span>
                        <Button onClick={handleToggle} variant="primary" icon={FileFilledMinor} accessibilityLabel="Show Instructions">Show Instructions</Button>
                      </div>
                    </InlineStack>
                    <Collapsible
                      open={openInstruction}
                      id="basic-collapsible"
                      transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                      expandOnPrint
                    >
                      <b>Instructions:</b>
                        <li>Visit the {discordDeveloperURL}, Click on the <b>New Application</b> button.</li>
                        <li>Enter a <b>Name</b> for your app, then press <b>Create</b>.</li>
                        <li>In the left sidebar, click on <b>OAuth2-{'>'}General</b>.</li>
                        <li>Enter <b>Redirects</b> as <b>{callbackUrl}</b> <Button onClick={handleCopy}>{copied ? 'Copied!' : 'Copy'}</Button></li>
                        <li>In the <b>General</b> tab, Copy on Client ID and reset Client Secret.</li>
                        <li>Paste <b>Client Id</b> and <b>Client Secret</b> in above fields.</li>
                        <li>Enter <b>identify+email+guilds.join</b> as a scope in above field.</li>
                    </Collapsible>
                  </FormLayout>
                </Form>
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Login Redirection URL"
              description="Redirect URL Path e.g. /cart"
            >
              <Card sectioned>
                <Form>
                  <FormLayout>
                    <TextField
                      label="Enter Login Redirect URL Path"
                      value={redirectUrl}
                      onChange={setRedirectUrl}
                      placeholder="/account/login"
                      prefix={`https://${storeDomain}`}
                    />
                  </FormLayout>
                </Form>
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="Login with Multipass Token"
              description="The Multipass secret can be found in your shop (Admin Settings > Checkout > Customer Accounts)"
            >
              <Card sectioned>
                <Form>
                  <FormLayout>
                      <Checkbox
                        label="Enable Login with Multipass"
                        checked={checkMultipassToken}
                        onChange={setCheckMultipassToken}
                      />
                      <TextField
                        label="Enter Login with Multipass"
                        value={multipassToken}
                        onChange={setMultipassToken}
                        placeholder="e.g. a4f281f679b537f416e162f802asa"
                        disabled={!checkMultipassToken}
                      />
                  </FormLayout>
                </Form>
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
          <SupportForm open={supportModalOpen} onClose={toggleSupportModal} />
          {toastMarkup}
        </Page>
        <Page></Page>
        <Page></Page>
        <Page></Page>
        </Frame>
      </div>
  );
}
